///////////////
//tatuff Fargona Tatu
export const environment = {
  otm: "tatuff",
  //website tsnqb.fbtuit.uz
  firebase: {
    email : "tatuff0tsnqb@gmail.com",
    p: '7^^VnW5&9vY97!xa'
  },
  production: true,
  http_auth: "IGIGDhvM9bmt5Wp6",
  otm_name: "TATU Farg‘ona filiali ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig:{ // pvorey
    apiKey: "AIzaSyAqG-vEaVdIofWMy2FbglnhKltHxz2_-kc",
    authDomain: "tatuff-3b746.firebaseapp.com",
    projectId: "tatuff-3b746",
    storageBucket: "tatuff-3b746.appspot.com",
    messagingSenderId: "916599363478",
    appId: "1:916599363478:web:ca0c36128fa33e82d36fb7",
    measurementId: "G-3PTZF2EX2K"
  },
  http: {
    socket:{
      path: 'ws://ferpi.tsnqb.uz/socket.io' 
     },
     editArea: {
      path: "https://tsnqb.fbtuit.uz/api/system/area",
    },
    addArea: {
      path: "https://tsnqb.fbtuit.uz/api/system/area",
    },
    deleteArea: {
      path: "https://tsnqb.fbtuit.uz/api/system/area",
    },
    removeDepartment: {
      path: "https://tsnqb.fbtuit.uz/api/system/department",
    },
    editDepartment: {
      path: "https://tsnqb.fbtuit.uz/api/system/department",
    },
    getDepartmentAArea: {
      path: "https://tsnqb.fbtuit.uz/api/system/getAll",
    },
    addDepartment: {
      path: "https://tsnqb.fbtuit.uz/api/system/department",
    },
    userRegister:{
      path: 'https://tsnqb.fbtuit.uz/api/auth/registration'
    },
    recoverPass:{
      path: 'https://tsnqb.fbtuit.uz/api/mailing/send-mail'
    },
    allRoles: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    removeRole: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    setRole: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    removeTeacher: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    allUsers: {
      path: 'https://tsnqb.fbtuit.uz/api/users/all'
    },
    updateUser: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    deleteUser: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    login: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login'
    },
    autologin: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/autologin'
    },
    get: {//https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
       path: "https://tsnqb.fbtuit.uz/db/2023/get_data.php",
       file: "pvorey.txt"
    },
    getFieldsInform: {
       path: "https://tsnqb.fbtuit.uz/db/2023/get_fields_inform.php"
    },
    delete_only_inform_file: {
      path: "https://tsnqb.fbtuit.uz/db/2023/delete_only_inform_file.php"
    },
    setInc: {
       path: "https://tsnqb.fbtuit.uz/db/2023/set_data_inc.php",
       file: "pvorey.txt"
    },
    del: {
       path: "https://tsnqb.fbtuit.uz/db/2023/del_data.php",
       file: "pvorey.txt"
    },
    setIncDelUpd: {
       path: "https://tsnqb.fbtuit.uz/db/2023/set_inc_del_upd.php",
       file: "pvorey.txt"
    },
    remDocDownGrade: {
       path: "https://tsnqb.fbtuit.uz/db/2023/rem_doc_with_down_grade.php",
    },
    googleScholarCited: {
       path: "https://tsnqb.fbtuit.uz/db/2023/google_scholar.php",
    },
    googleScholarCitedAll: {
       path: "https://tsnqb.fbtuit.uz/db/2023/google_scholar_all.php",
    },
    setNewPvo: {
       path: "https://tsnqb.fbtuit.uz/db/2023/set_new_pvo.php",
    },
    removePvo: {
       path: "https://tsnqb.fbtuit.uz/db/2023/remove_pvo.php",
    },
    controlIndexStatus: {
       path: "https://tsnqb.fbtuit.uz/db/2023/control_index_status.php",
    },
    createAnnounce: {
       path: "https://tsnqb.fbtuit.uz/db/2023/create_announce.php",
    },
    getAnnounces: {
       path: "https://tsnqb.fbtuit.uz/db/2023/get_announces.php",
    },
    removeAnnounce: {
       path: "https://tsnqb.fbtuit.uz/db/2023/remove_announce.php",
    },
    editUserAccaunt: {
       path: "https://tsnqb.fbtuit.uz/db/2023/edit_user_account.php",
    },
    createIndex: {
       path: "https://tsnqb.fbtuit.uz/db/2023/create_index.php",
    }
  },
  tizim: [
    {
      fakultet: "Kompyuter injiniring",
      kafedralar: [
         "Axborot texnologiyalari",
         "Kompyuter tizimlari",
         "Tabiiy fanlar",
      ]
    },
    {
      fakultet: "Dasturiy injiniringi va raqamli iqtisodiyot",
      kafedralar: [
         "Axborot xavfsizligi",
         "Dasturiy injiniring",
         "O'zbek tili va gumanitar fanlar",
      ]
    },
    {
      fakultet: "Telekomunikatsiya injiniringi va kasb ta'limi",
      kafedralar: [
        "Telekomunikatsiya injiniringi",
        "Axborot ta’lim texnologiyalari",
        "Xorijiy tillar",
      ]
    } 
  ],
  grastud: 556,
  fakultet: ["Kompyuter injiniring", "Dasturiy injiniringi va raqamli iqtisodiyot", "Telekomunikatsiya injiniringi va kasb ta'limi"],
  kafedra: [ 
    "Axborot texnologiyalari",
    "Kompyuter tizimlari",
    "Tabiiy fanlar",
    "Axborot xavfsizligi",
    "Dasturiy injiniring",
    "O'zbek tili va gumanitar fanlar",
    "Telekomunikatsiya injiniringi",
    "Axborot ta’lim texnologiyalari",
    "Xorijiy tillar",
  ]
};
